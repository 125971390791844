import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { UserService } from "./user.service";
import { take, map } from "rxjs/operators";
import { SocketIoService } from "./socket-io.service";
import { EmployeeService } from "./employee.service";
// import { SocketIoService } from './socket-io.service';

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private socketIoService: SocketIoService,
    private employeeService: EmployeeService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        let checkOTP = localStorage.getItem("ISOTPVERIFIED") ? true : false;
        let canLogin = isLoggedIn && checkOTP;

        if (!canLogin) {
          // this.router.navigate(['/']);
          this.userService.logout();
          return false;
        }
  
        return true;
      })
    );
  }
}
