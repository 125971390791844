import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedMaterialModule } from "../shared-material.module";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SearchModule } from "../search/search.module";
import { SharedPipesModule } from "../pipes/shared-pipes.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedDirectivesModule } from "../directives/shared-directives.module";

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from "./header-side/header-side.component";
import { SidebarSideComponent } from "./sidebar-side/sidebar-side.component";
// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from "./header-top/header-top.component";
import { SidebarTopComponent } from "./sidebar-top/sidebar-top.component";
// ALWAYS REQUIRED
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { SidenavComponent } from "./sidenav/sidenav.component";
import { FooterComponent } from "./footer/footer.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { ButtonLoadingComponent } from "./button-loading/button-loading.component";
import {
  tgssSidebarComponent,
  tgssSidebarTogglerDirective,
} from "./tgss-sidebar/tgss-sidebar.component";
import { BottomSheetShareComponent } from "./bottom-sheet-share/bottom-sheet-share.component";
import { tgssExampleViewerComponent } from "./example-viewer/example-viewer.component";
import { tgssExampleViewerTemplateComponent } from "./example-viewer-template/example-viewer-template.component";
import { AppLoaderComponent } from "./app-loader/app-loader.component";
import { CropperComponent } from "./crop/crop.component";
import { TimeagoModule } from "ngx-timeago";
import { TgssSelectBoxModule } from "./tgss-select-box/tgss-select-box.module";
// import { ReadmoreComponent } from 'app/modules/admin/cancelled-details/readmore/readmore.component';
import { ReadMoreComponent } from "./read-more/read-more.component";
import { TGSSSelectBoxV2Module } from "./tgss-select-box-v2/tgss-select-box-v2.module";
import { ProductLoaderModule } from "./product-loader/product-loader.module";
import { ChatPopUpComponent } from "./chat-pop-up/chat-pop-up.component";
import { AudioPlayerComponent } from "./audio-player/audio-player.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ChatViewAdminComponent } from "./chat-view-admin/chat-view-admin.component";
import { ChatPopUpAdminComponent } from "./chat-pop-up-admin/chat-pop-up-admin.component";

// import { ReadmoreComponent } from 'app/modules/admin/cancelled-details/readmore/readmore.component';

const components = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  ButtonLoadingComponent,
  tgssSidebarComponent,
  FooterComponent,
  tgssSidebarTogglerDirective,
  BottomSheetShareComponent,
  tgssExampleViewerComponent,
  tgssExampleViewerTemplateComponent,
  AppLoaderComponent,
  CropperComponent,
  ReadMoreComponent,
  ChatPopUpComponent,
  AudioPlayerComponent,
  ChatViewAdminComponent,
  ChatPopUpAdminComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SearchModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
    TgssSelectBoxModule,
    TGSSSelectBoxV2Module,
    ProductLoaderModule,
    TimeagoModule.forRoot(),
    InfiniteScrollModule,
  ],
  declarations: components,
  entryComponents: [BottomSheetShareComponent],
  exports: components,
})
export class SharedComponentsModule {}
