import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductLoaderComponent } from './product-loader.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { FlexLayoutModule } from '@angular/flex-layout';
@NgModule({
  declarations: [ProductLoaderComponent],
  exports:[ProductLoaderComponent],
  providers : [ProductLoaderComponent],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    FlexLayoutModule
  ]
})
export class ProductLoaderModule { }
