import { ValidatorFn, FormBuilder, FormGroup, Validators } from '@angular/forms';


export function checkNull(value: any) {
  if (value != null && value != undefined && value != '') {
    return true
  } else {
    return false
  }
}

export function returnDateString(data: any) {
  let date = new Date(data)
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export function FormattedDate(data: any) {
  let date = new Date(data)
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
}

function checkFromDateToDate(fromDate, todate) {
  let from = new Date(fromDate);
  let to = new Date(todate);
  if ((from.getFullYear() <= to.getFullYear())) {

    if ((from.getFullYear() == to.getFullYear())) {
      if (((from.getMonth() + 1) <= (to.getMonth() + 1))) {
        if (((from.getMonth() + 1) == (to.getMonth() + 1))) {
          if ((from.getDate() <= to.getDate())) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return false
      }
    } else {
      return true
    }
  } else {
    return false
  }
}

/*
    if (((from.getMonth() + 1) <= (to.getMonth() + 1))) {
      if (((from.getMonth() + 1) == (to.getMonth() + 1))) {
        if ((from.getDate() <= to.getDate())) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
 */
function checkFromTime$fromDate($date, $time) {
  const currentDate = new Date();
  const currentHour = new Date().getHours();
  const currentMinutes = new Date().getMinutes();
  const date = new Date($date);
  const time = $time.split(':');
  const hours = Number(time[0]);
  const minutes = Number(time[1]);
  //@ If today the time should be greater than 10 mins
  if (returnDateString(currentDate) == returnDateString(date)) {
    if ((hours >= currentHour)) {
      if (hours == currentHour) {
        if ((minutes >= currentMinutes)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  } else {
    return true
  }
}

function checkFromDate(fDate) {
  console.log(fDate, 'this from the service')
  const currentDate = new Date().getDate()
  const fromDate = new Date(fDate).getDate();

  const currentMonth = new Date().getMonth() + 1
  const fromMonth = new Date(fDate).getMonth() + 1;

  const currentYear = new Date().getFullYear()
  const fromYear = new Date(fDate).getFullYear();

  if (fromYear >= currentYear) {

    if (fromYear == currentYear) {
      if (fromMonth >= currentMonth) {

        if (fromMonth == currentMonth) {
          if (fromDate >= currentDate) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }

      } else {
        return false
      }

    } else {
      return true
    }


  } else {
    return false
  }
}

/*
 
 */


function checkAllDateAndTimes(fDate, fTime: string, tDate, tTime: string) {
  const fromDate = new Date(fDate)
  const toDate = new Date(tDate)
  const fromTime = fTime.split(':');
  const toTime = tTime.split(':');
  const fromHours = Number(fromTime[0]);
  const toHours = Number(toTime[0]);
  const fromMins = Number(fromTime[1]);
  const toMins = Number(toTime[1]);

  if (returnDateString(fromDate) === returnDateString(toDate)) {
    if ((fromHours <= toHours)) {
      if (fromHours == toHours) {
        if ((fromMins <= (toMins + 10))) {
          return true
        } else {
          return false
        }

      } else {
        return true
      }
    } else {
      return false
    }
  } else {
    return true
  }

}

/***********************************From Date And To Date Validation************************************ */
export const fromDate$toDate: ValidatorFn = (formGroup: FormGroup) => {
  const startDate = formGroup.get(`startDate`).value;
  const endDate = formGroup.get(`endDate`).value;
  const startTime = formGroup.get(`startTime`).value;
  const endTime = formGroup.get(`endTime`).value;

  //@First from date and to date;
  if (checkNull(startDate) && checkNull(endDate)) {
    console.log(startDate, endDate);

    if (checkFromDateToDate(startDate, endDate)) {
      return null
    } else {
      return { dateError: true }
    }
  } else {
    return null
  }
}

/*******************************************From Time And From date validation ***************************** */

export const fromdate$fromTime: ValidatorFn = (formGroup: FormGroup) => {
  const startDate = formGroup.get(`startDate`).value;
  const endDate = formGroup.get(`endDate`).value;
  const startTime = formGroup.get(`startTime`).value;
  const endTime = formGroup.get(`endTime`).value;
  if (checkNull(startDate) && checkNull(startTime)) {
    if (checkFromTime$fromDate(startDate, startTime)) {
      return null
    } else {
      return { invalidFromtime: true }
    }
  } else {
    return null
  }
}




/************************************Same Date and Different Time Validation*****************************/

export const sameDateDifferentTime: ValidatorFn = (formGroup: FormGroup) => {
  const startDate = formGroup.get(`startDate`).value;
  const endDate = formGroup.get(`endDate`).value;
  const startTime = formGroup.get(`startTime`).value;
  const endTime = formGroup.get(`endTime`).value;

  if (checkNull(startDate) && checkNull(endDate) && checkNull(startTime) && checkNull(endTime)) {

    if (checkAllDateAndTimes(startDate, startTime, endDate, endTime)) {
      return null
    } else {
      return { improperTime: true }
    }
  } else {
    return null
  }

}


export const fromDateValidation: ValidatorFn = (formGroup: FormGroup) => {
  const startDate = formGroup.get(`startDate`).value;
  const endDate = formGroup.get(`endDate`).value;
  const startTime = formGroup.get(`startTime`).value;
  const endTime = formGroup.get(`endTime`).value;

  if (checkNull(startDate)) {
    console.log(startDate, "in date funtion!")
    if (checkFromDate(startDate)) {
      return null
    } else {
      return { invalidFromdate: true }
    }
  } else {
    return null

  }

}