import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

// import { ThemeService } from './shared/services/theme.service';

import { filter } from 'rxjs/operators';
import { decodedToken, getToken } from './core/helpers/token.helper';
import { ConfigService } from './core/services/config.service';
import { LayoutService } from './core/services/layout.service';
import { NavigationService } from './core/services/navigation.service';
import { PermissionService } from './core/services/permission.service';
import { RoutePartsService } from './core/services/route-parts.service';
// import { LayoutService } from './shared/services/layout.service';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from "app/shared/helpers/format-datepicker";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppServerDatePipe } from "app/shared/pipes/app-config.pipe";
import { RouterActiveService } from './core/services/router-active.service';
import { checkNull } from './core/services/date-and-time-validators.service';
import { BellCountService } from './core/services/bell-count.service';
import { UserService } from './core/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    AppServerDatePipe,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Print App';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private configService: ConfigService,
    private permSer: PermissionService,
    private navSer: NavigationService,
    private layout: LayoutService,
    private routeractive: RouterActiveService,
    private countService: BellCountService,
    private userservice: UserService
  ) { }

  ngOnInit() {
    this.setPrefernce();
  }
  ngAfterViewInit() {
    this.changePageTitle();
    this.getRouter()
  }


  getRouter() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      // console.log(window.location.pathname, 'pathname', this.router.url);
      this.routeractive.setRoute(String(this.router.url))
    });
    this.activeRoute.queryParams.subscribe((res: any) => {
      if (checkNull(res?.random_id)) {
        this.countService.randomIdRead(res?.random_id);
      }
    })
  }
  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length)
        return this.title.setTitle(this.appTitle);
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => { return `${partA}` });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }


  setPrefernce() {
    if (getToken()) {
      /* set preference */
      this.configService.init();
      let langPrefer = this.configService.language;
      let roleId = this.configService.roleId;
      let permission = this.configService.permission;
      let portalType = this.configService.portalType;
      let themeColor = this.configService.themeColour;
      // handle permission based menu
      this.permSer.init(Number(roleId), portalType, permission);
      let authMenu = this.permSer.authMenu;
      this.navSer.publish(authMenu);
      this.layout.publishLayoutChange({ matTheme: themeColor });
    }
  }

}
